import React, { FC, useState, useEffect, useRef, useContext } from "react";
import Stripe from "images/sLogo.png";
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL } from "Constant/System";
import _, { lowerCase, map, upperFirst } from "lodash";
import { postJobs, postProfile } from "Services/API/Post";
import { addToast } from "shared/Toast";
import {
  CurrentUser,
  GetUserId,
  getCities,
  getDistricts,
  getJobDetails,
  getOrderDetails,
  getSriCities,
  getSriDistricts
} from "Services/API/Get";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Box
} from "@mui/material";
import {
  EditUserQuery,
  UpdateJobs,
  UpdatePackage,
  UpdateProfiles
} from "Services/API/Put";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import moment from "moment";
import SubData from "data/australian_postcodes.json";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LoadingSpinner from "shared/LoadingSpinner";
import DatePicker from "react-multi-date-picker";
import BotToast from "shared/BotToast";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useTranslation } from "react-i18next";
import WishContext from "Context/WishContext";
import { DeleteProfile } from "Services/API/Delete";
import ModalQuickView from "components/ModalJob";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2d867b"
    },
    secondary: {
      main: "#2d867b"
    }
  }
});

const CATEG = [
  {
    value: "Seasonal",
    name: "Seasonal"
  },
  {
    value: "Casual",
    name: "Casual"
  },
  {
    value: "Temporary",
    name: "Temporary"
  },
  // {
  //     value: "Fixed",
  //     name: "Fixed"
  // },
  {
    value: "Part Time",
    name: "Permanent – Part Time"
  },
  {
    value: "Full Time",
    name: "Permanent – Full time"
  }
];

const AGEarr = [
  "18+",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "75+"
];

const CASTarr = [
  // "Any",
  "Bathagama",
  "Choliya",
  "Dewa",
  "Colombo Chetti",
  "Durawa",
  "Govigama",
  "Hunu",
  "Karawa",
  "Kumbal",
  "Nekath Oli",
  "Panna",
  "Radala",
  "Rajaka",
  "Salagama",
  "Vishwa",
  "Other"
];

const RACEarr = ["Sinhalese", "Tamil", "Muslim", "Burgher"];

const RELIGIONarr = ["Buddhism", "Christian", "Islam", "Hindu", "Other"];

const EDUarr = [
  "Up to O/L",
  "Up to A/L",
  "Diploma",
  "Higher Diploma",
  "Bachelor Degree",
  "Master Degree",
  "PhD"
];

const OCUarr = [
  "Accountant (ගණකාධිකාරි)",
  "Admin Executive ",
  "Admin Manager ",
  "Agriculture Manager (කෘෂිකාර්මික කළමනාකරු)",
  "Agriculture Officer (කෘෂිකර්ම නිලධාරී)",
  "Air Craft Engineer (ගුවන් සේවා ඉන්ජිනේරු)",
  "Air Hostest (ගුවන් සේවිකා)",
  "Architecture (ගෘහනිර්මාණ ශිල්පී)",
  "Assistant (සහයක)",
  "Assistant Bank Manager (සහකාර බැංකු නිලධාරී )",
  "Assistant HR Manager (සහකාර මානව සම්පත් කළමනාකරු)",
  "Assistant Marketing Manager (සහකාර අලෙවි කළමනාකරු)",
  "Assistant Sales Manager (සහකාර විකුණුම් කළමනාකරු)",
  "Assistant T & D Manager (සහකාර  පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Assitant Manager - Procument ",
  "Auditor (විගණක)",
  "Bank Manager (බැංකු කළමනාකරු)",
  "Bank Officer (බැංකු නිලධාරී )",
  "Business Analysts ",
  "Businessman ( ව්‍යාපාරික)",
  "Civil Engineer (සිවිල් ඉන්ජිනේරු)",
  "Doctor (වෛද්‍ය)",
  "Draftman ",
  "Driver (රියදුරු )",
  "Electrical Engineer (විදුලි  ඉන්ජිනේරු)",
  "English Teacher (ඉංග්‍රීසි ගුරුවරයකු)",
  "Entrepreneur (ව්‍යවසායක)",
  "Executive - Marketing (අලෙවි නිලධාරී)",
  "Finance Manager (මුල්‍ය කළමනාකරු)",
  "Financial Controller (මුල්‍ය පාලක)",
  "Government Employee (රාජ්‍ය නිලධාරී)",
  "Government Sector Manager ",
  "Head of Marketing (අලෙවි ප්‍රධානි )",
  "Head of Sales (විකුණුම් ප්‍රධානි)",
  "HR Executive (මානව සම්පත් විධායක )",
  "HR Manager (මානව සම්පත් කළමනාකරු)",
  "IT Business Analysts ",
  "IT Manager (තොරතුරු තාක්ෂණික කළමනාකරු)",
  "IT Professional (තොරතුරු තාක්ෂණික වෘතියවේදී )",
  "Junior Manager (කණිෂ්ඨ කළමනාකරු)",
  "Lawyer (නීතිඥ)",
  "Lecturer (කථිකාචාර්ය)",
  "Manager - Audit (විගණන කළමනාකරු)",
  "Manager (කළමනාකරු)",
  "Marketing Manager (අලෙවි කළමනාකරු )",
  "Nurse (හෙදියක)",
  "Other (වෙනත්)",
  "Pilot (ගුවන් නියමු)",
  "Policeman (පොලිස් නිලධාරී)",
  "Policewoman (පොලිස් නිලධාරීනි)",
  "Pre-School Teacher (පෙර පාසල්  ගුරුවරි)",
  "Principle (විදුහල්පති )",
  "Private Sector Manager ",
  "Procument Executive ",
  "Procument Manager (",
  "Receptionist (පිළිගැනීමේ නිලධාරී)",
  "Sales Executive ( විකුණුම් නිලධාරී)",
  "Sales Manager (විකුණුම් කළමනාකරු)",
  "Scientist (විද්‍යාඥයා)",
  "Security Officer (ආරක්ෂක නිලධාරී)",
  "Senior Accountant (ජ්‍යෙෂ්ඨ ගණකාධිකාරි)",
  "Senior Auditor (ජ්‍යෙෂ්ඨ විගණක)",
  "Senior Executive - HR (ජ්‍යෙෂ්ඨ මානව සම්පත් කළමනාකරු)",
  "Senior Executive - Marketing (ජ්‍යෙෂ්ඨ අලෙවි නිලධාරී )",
  "Senior IT Manager (ජ්‍යෙෂ්ඨ තොරතුරු තාක්ෂණික කළමනාකරු)",
  "Senior Lecturer (ජ්‍යෙෂ්ඨ කථිකාචාර්ය)",
  "Senior Manager (ජ්‍යෙෂ්ඨ කළමනාකරු)",
  "Senior Sales Executive (ජ්‍යෙෂ්ඨ විකුණුම් නිලධාරී)",
  "Senior Software Engineer (ජ්‍යෙෂ්ඨ මෘදුකාංග ඉංජිනේරු)",
  "Software Engineer (මෘදුකාංග ඉංජිනේරු)",
  "Stock Broker ",
  "T & D Manager (පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Teacher (ගුරුවරයකු)",
  "Water Engineer (ජල   ඉන්ජිනේරු )"
];

const MARarr = ["Never Married", "Divorced", "Single Parent"];

const GENDarr = ["Male", "Female"];

const HORRarr = ["Kuja 1", "Kuja 7", "Shani 7", "Other"];

const TITarr = ["Mr", "Mrs", "Miss", "Other"];

const COUNTArr = [
  "Afghanistan",
  "Åland (Finland)",
  "Albania",
  "Algeria",
  "American Samoa (US)",
  "Andorra",
  "Angola",
  "Anguilla (BOT)",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Artsakh",
  "Aruba (Netherlands)",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda (BOT)",
  "Bhutan",
  "Bolivia",
  "Bonaire (Netherlands)",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands (BOT)",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands (BOT)",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island (Australia)",
  "Cocos (Keeling) Islands (Australia)",
  "Colombia",
  "Comoros",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao (Netherlands)",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "DR Congo",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (BOT)",
  "Faroe Islands (Denmark)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana (France)",
  "French Polynesia (France)",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar (BOT)",
  "Greece",
  "Greenland (Denmark)",
  "Grenada",
  "Guadeloupe (France)",
  "Guam (US)",
  "Guatemala",
  "Guernsey (Crown Dependency)",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man (Crown Dependency)",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey (Crown Dependency)",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique (France)",
  "Mauritania",
  "Mauritius",
  "Mayotte (France)",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat (BOT)",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia (France)",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island (Australia)",
  "North Korea",
  "North Macedonia",
  "Northern Cyprus",
  "Northern Mariana Islands (US)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands (BOT)",
  "Poland",
  "Portugal",
  "Puerto Rico (US)",
  "Qatar",
  "Réunion (France)",
  "Romania",
  "Russia",
  "Rwanda",
  "Saba (Netherlands)",
  "Saint Barthélemy (France)",
  "Saint Helena, Ascension and Tristan da Cunha (BOT)",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (France)",
  "Saint Pierre and Miquelon (France)",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "São Tomé and Príncipe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Eustatius (Netherlands)",
  "Sint Maarten (Netherlands)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen (Norway)",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau (NZ)",
  "Tonga",
  "Transnistria",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (BOT)",
  "Tuvalu",
  "U.S. Virgin Islands (US)",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna (France)",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];

const TYPEarr = ["Father", "Mother", "Proposal seeker", "Other"];

const TYPEarrSi = ["පියා", "මව", "යෝජනා අපේක්ෂක", "වෙනත්"];

const TITarrSi = ["මයා", "මිය", "මෙනවිය", "වෙනත්"];

const HORRarrSi = ["කුජ 1", "කුජ 7", "ශනි 7", "වෙනත්"];

const GENDarrSi = ["සහකරු", "සහකාරිය"];

const AGEarrSi = [
  "18+",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "75+"
];

const CASTarrSi = [
  // "ඕනෑම",
  "බත්ගම",
  "චෝලිය",
  "දේව",
  "කොළඹ චෙට්ටි",
  "දුරාව",
  "ගොවිගම",
  "හුණු",
  "කරාව",
  "කුඹල්",
  "නැකැත් ඔලි",
  "පැන්න",
  "රදල",
  "රාජක",
  "සලාගම",
  "විශ්ව",
  "වෙනත්"
];

const RACEarrSi = ["සිංහල", "දෙමළ", "මුස්ලිම්", "බර්ගර්"];

const RELIGIONarrSi = ["බෞද්ධ", "ක්‍රිස්තියානි", "ඉස්ලාම්", "හින්දු", "වෙනත්"];

const EDUarrSi = [
  "සාමාන්‍ය පෙළ දක්වා",
  "උසස් පෙළ දක්වා",
  "ඩිප්ලෝමා",
  "උසස් ඩිප්ලෝමා",
  "උපාධි",
  "පශ්චාත් උපාධි",
  "ආචාර්‍ය උපාධි"
];

const OCUarrSi = [
  "Accountant (ගණකාධිකාරි)",
  "Admin Executive ",
  "Admin Manager ",
  "Agriculture Manager (කෘෂිකාර්මික කළමනාකරු)",
  "Agriculture Officer (කෘෂිකර්ම නිලධාරී)",
  "Air Craft Engineer (ගුවන් සේවා ඉන්ජිනේරු)",
  "Air Hostest (ගුවන් සේවිකා)",
  "Architecture (ගෘහනිර්මාණ ශිල්පී)",
  "Assistant (සහයක)",
  "Assistant Bank Manager (සහකාර බැංකු නිලධාරී )",
  "Assistant HR Manager (සහකාර මානව සම්පත් කළමනාකරු)",
  "Assistant Marketing Manager (සහකාර අලෙවි කළමනාකරු)",
  "Assistant Sales Manager (සහකාර විකුණුම් කළමනාකරු)",
  "Assistant T & D Manager (සහකාර  පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Assitant Manager - Procument ",
  "Auditor (විගණක)",
  "Bank Manager (බැංකු කළමනාකරු)",
  "Bank Officer (බැංකු නිලධාරී )",
  "Business Analysts ",
  "Businessman ( ව්‍යාපාරික)",
  "Civil Engineer (සිවිල් ඉන්ජිනේරු)",
  "Doctor (වෛද්‍ය)",
  "Draftman ",
  "Driver (රියදුරු )",
  "Electrical Engineer (විදුලි  ඉන්ජිනේරු)",
  "English Teacher (ඉංග්‍රීසි ගුරුවරයකු)",
  "Entrepreneur (ව්‍යවසායක)",
  "Executive - Marketing (අලෙවි නිලධාරී)",
  "Finance Manager (මුල්‍ය කළමනාකරු)",
  "Financial Controller (මුල්‍ය පාලක)",
  "Government Employee (රාජ්‍ය නිලධාරී)",
  "Government Sector Manager ",
  "Head of Marketing (අලෙවි ප්‍රධානි )",
  "Head of Sales (විකුණුම් ප්‍රධානි)",
  "HR Executive (මානව සම්පත් විධායක )",
  "HR Manager (මානව සම්පත් කළමනාකරු)",
  "IT Business Analysts ",
  "IT Manager (තොරතුරු තාක්ෂණික කළමනාකරු)",
  "IT Professional (තොරතුරු තාක්ෂණික වෘතියවේදී )",
  "Junior Manager (කණිෂ්ඨ කළමනාකරු)",
  "Lawyer (නීතිඥ)",
  "Lecturer (කථිකාචාර්ය)",
  "Manager - Audit (විගණන කළමනාකරු)",
  "Manager (කළමනාකරු)",
  "Marketing Manager (අලෙවි කළමනාකරු )",
  "Nurse (හෙදියක)",
  "Other (වෙනත්)",
  "Pilot (ගුවන් නියමු)",
  "Policeman (පොලිස් නිලධාරී)",
  "Policewoman (පොලිස් නිලධාරීනි)",
  "Pre-School Teacher (පෙර පාසල්  ගුරුවරි)",
  "Principle (විදුහල්පති )",
  "Private Sector Manager ",
  "Procument Executive ",
  "Procument Manager (",
  "Receptionist (පිළිගැනීමේ නිලධාරී)",
  "Sales Executive ( විකුණුම් නිලධාරී)",
  "Sales Manager (විකුණුම් කළමනාකරු)",
  "Scientist (විද්‍යාඥයා)",
  "Security Officer (ආරක්ෂක නිලධාරී)",
  "Senior Accountant (ජ්‍යෙෂ්ඨ ගණකාධිකාරි)",
  "Senior Auditor (ජ්‍යෙෂ්ඨ විගණක)",
  "Senior Executive - HR (ජ්‍යෙෂ්ඨ මානව සම්පත් කළමනාකරු)",
  "Senior Executive - Marketing (ජ්‍යෙෂ්ඨ අලෙවි නිලධාරී )",
  "Senior IT Manager (ජ්‍යෙෂ්ඨ තොරතුරු තාක්ෂණික කළමනාකරු)",
  "Senior Lecturer (ජ්‍යෙෂ්ඨ කථිකාචාර්ය)",
  "Senior Manager (ජ්‍යෙෂ්ඨ කළමනාකරු)",
  "Senior Sales Executive (ජ්‍යෙෂ්ඨ විකුණුම් නිලධාරී)",
  "Senior Software Engineer (ජ්‍යෙෂ්ඨ මෘදුකාංග ඉංජිනේරු)",
  "Software Engineer (මෘදුකාංග ඉංජිනේරු)",
  "Stock Broker ",
  "T & D Manager (පුහුණු සහ සංවර්ධන කළමනාකරු)",
  "Teacher (ගුරුවරයකු)",
  "Water Engineer (ජල   ඉන්ජිනේරු )"
];

const MARarrSi = ["අවිවාහක", "දික්කසාද", "තනි දෙමාපිය (Single Parent)"];

const Jobs = ({
  selectedJob = null,
  onCloseModalQuickView,
  setShowModalQuickView3,
  modal = false,
  index = null,
  owner = null,
  sethideForm = null
}) => {
  const { t } = useTranslation();

  const { lan, setLan } = useContext(WishContext);

  const [images, setImages] = useState(
    selectedJob?.document ? JSON.parse(selectedJob?.document) : []
  );

  const [title, settitle] = useState("");
  const [jobType, setjobType] = useState(
    selectedJob ? selectedJob?.jobType : ""
  );
  const [country, setcountry] = useState(
    selectedJob ? selectedJob?.currentResidesCountry : ""
  );
  const [homeDistrict, sethomeDistrict] = useState(
    selectedJob ? selectedJob?.district : ""
  );
  const [homeCity, sethomeCity] = useState(
    selectedJob ? selectedJob?.city : ""
  );
  const [duration, setduration] = useState(
    selectedJob ? selectedJob?.duration : ""
  );
  const [description, setdescription] = useState(
    selectedJob ? selectedJob?.description : ""
  );
  const [currency, setcurrency] = useState(
    selectedJob ? selectedJob?.currency : "AUD"
  );
  const [rate, setrate] = useState(selectedJob ? selectedJob?.hourlyRate : "");
  const [expire, setexpire] = useState(
    selectedJob ? moment(selectedJob?.expiryDate).toDate() : ""
  );
  // const [Keywords, setKeywords] = useState(selectedJob ? JSON.parse(selectedJob?.keywords).join(" ") : "");
  const [Keywords, setKeywords] = useState("");

  const [oldPkg, setoldPkg] = useState(
    selectedJob ? selectedJob?.packageId?.toString() : ""
  );

  const [errimages, seterrImages] = useState([]);

  const [errtitle, seterrtitle] = useState(false);
  const [errjobType, seterrjobType] = useState(false);
  const [errcountry, seterrcountry] = useState(false);
  const [errsuburb, seterrsuburb] = useState(false);
  const [errduration, seterrduration] = useState(false);
  const [errdescription, seterrdescription] = useState(false);
  const [errcurrency, seterrcurrency] = useState(false);
  const [errrate, seterrrate] = useState(false);
  const [errexpire, seterrexpire] = useState(false);
  const [errKeywords, seterrKeywords] = useState(false);
  const [errhomeDistrict, seterrhomeDistrict] = useState(false);
  const [errhomeCity, seterrhomeCity] = useState(false);

  const [homeDistrictId, sethomeDistrictId] = useState(0);
  const [allHCities, setallHCities] = useState([]);
  const [allHDistricts, setallHDistricts] = useState([]);

  const [allHCitiespro, setallHCitiespro] = useState([]);
  const [allHDistrictspro, setallHDistrictspro] = useState([]);
  const [homeDistrictId2, sethomeDistrictId2] = useState(0);

  const [currUser, setcurrUser] = useState("");

  const [packageId, setpackageId] = useState(
    selectedJob ? selectedJob?.packageId?.toString() : ""
  );

  const [lOpen, setlOpen] = useState(false);

  const [errpackages, seterrpackages] = useState(false);

  const history = useHistory();

  const [newSubArray, setnewSubArray] = useState([]);
  const [newSubs, setnewSubs] = useState([]);

  const [bOpen, setbOpen] = useState(false);
  const [bMsg, setbMsg] = useState("");
  const [bStatus, setbStatus] = useState("");

  const ref = useRef(null);

  const [editorData, setEditorData] = useState(
    selectedJob?.duties ? selectedJob?.duties : "<br/><br/><br/><br/><br/>"
  );

  const [newSubs2, setnewSubs2] = useState([]);

  const [gender, setgender] = useState(selectedJob ? selectedJob?.gender : "");
  const [horoscopStatus, sethoroscopStatus] = useState(
    selectedJob ? selectedJob?.horoscopStatus : ""
  );
  const [age, setage] = useState(selectedJob ? selectedJob?.age : "");
  const [cast, setcast] = useState(selectedJob ? selectedJob?.cast : "");
  const [race, setrace] = useState(selectedJob ? selectedJob?.race : "");
  const [religion, setreligion] = useState(
    selectedJob ? selectedJob?.religion : ""
  );
  const [height, setheight] = useState(selectedJob ? selectedJob?.height : "");
  const [feet, setfeet] = useState(selectedJob ? selectedJob?.feet : "");
  const [inch, setinch] = useState(selectedJob ? selectedJob?.inch : "");
  const [educationStatus, seteducationStatus] = useState(
    selectedJob ? selectedJob?.educationStatus : ""
  );
  const [occupation, setoccupation] = useState(
    selectedJob ? selectedJob?.occupation : ""
  );
  const [marriageStatus, setmarriageStatus] = useState(
    selectedJob ? selectedJob?.marriageStatus : ""
  );
  const [familyBackground, setfamilyBackground] = useState(
    selectedJob ? selectedJob?.familyBackground : ""
  );
  const [mobile, setmobile] = useState(
    selectedJob ? selectedJob?.contactNo : ""
  );
  const [whatsAppNo, setwhatsAppNo] = useState(
    selectedJob ? selectedJob?.whatsAppNo : ""
  );
  const [mobile2, setmobile2] = useState(
    selectedJob ? selectedJob?.whatsAppNo : ""
  );
  const [email, setemail] = useState(selectedJob ? selectedJob?.mainEmail : "");
  const [email2, setemail2] = useState(
    selectedJob ? selectedJob?.secondaryEmail : ""
  );

  const [errgender, seterrgender] = useState(false);
  const [errhoroscopStatus, seterrhoroscopStatus] = useState(false);
  const [errage, seterrage] = useState(false);
  const [errcast, seterrcast] = useState(false);
  const [errrace, seterrrace] = useState(false);
  const [errreligion, seterrreligion] = useState(false);
  const [errheight, seterrheight] = useState(false);
  const [erreducationStatus, seterreducationStatus] = useState(false);
  const [errocupation, seterrocupation] = useState(false);
  const [errmarriageStatus, seterrmarriageStatus] = useState(false);
  const [errfamilyBackground, seterrfamilyBackground] = useState(false);
  const [errmobile, seterrmobile] = useState("");
  const [errwhatsAppNo, seterrwhatsAppNo] = useState("");
  const [errmobile2, seterrmobile2] = useState("");
  const [erremail, seterremail] = useState("");
  const [erremail2, seterremail2] = useState(false);

  const [userFname, setuserFname] = useState("");
  const [userAddress, setuserAddress] = useState("");
  const [userContact, setuserContact] = useState("");
  const [userCountry, setuserCountry] = useState("");
  const [userDistrict, setuserDistrict] = useState("");
  const [userCity, setuserCity] = useState("");
  const [userType, setuserType] = useState("");

  const [erruserFname, seterruserFname] = useState(false);
  const [erruserAddress, seterruserAddress] = useState(false);
  const [erruserContact, seterruserContact] = useState("");
  const [erruserCountry, seterruserCountry] = useState(false);
  const [erruserDistrict, seterruserDistrict] = useState(false);
  const [erruserCity, seterruserCity] = useState(false);
  const [erruserType, seterruserType] = useState(false);

  const [disEdit, setdisEdit] = useState(
    owner ? true : !selectedJob ? false : modal ? false : true
  );

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  useEffect(() => {
    // if (!selectedJob) {
    //     if (lan === "English") {
    //         setcountry("Sri Lanka")
    //     } else {
    //         setcountry("ශ්‍රී ලංකාව")
    //     }
    // }
  }, [lan, selectedJob]);

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  React.useEffect(() => {
    let newSubArray = JSON.parse(JSON.stringify(SubData));

    newSubArray = newSubArray?.map((d) => {
      if (d.type === "Delivery Area") {
        return {
          ...d,
          locality: upperFirst(lowerCase(d.locality))
        };
      }
    });

    newSubArray = _.compact(newSubArray);

    setnewSubArray(_.orderBy(newSubArray, ["locality"], ["asc"]));

    console.log(
      ">>>>>>>subs",
      newSubArray.filter((u) => u.state === 0)
    );
  }, []);

  const packageSelect = (item) => {
    console.log("pkg>>>>", item);

    let obj = {};

    if (item === "1") {
      obj = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    }

    // let obj = {
    //     email: currUser?.email,
    //     amountPayable: item.pricing.split("$")[1],
    //     packageDetails: [
    //         {
    //             packagePrice: item.pricing.split("$")[1],
    //             qty: 1,
    //             packageName: item.name + " " + "Subscription",
    //         }
    //     ],
    //     packageId: item.id,
    //     userId: currUser?.id,
    // }

    console.log("obj>>>", obj);

    setlOpen(true);

    UpdatePackage(obj, currUser?.id)
      .then((res) => {
        console.log("res>>>>", res.data);

        if (res.data !== undefined) {
          if (res.data === "success") {
            history.push("/profile");
          } else {
            window.location.href = res.data;
          }
        }
      })
      .catch((error) => {
        setlOpen(false);
        console.log("error", error);
        console.log("current user error", error.response.data.message);
        if (error.response.data.statusCode === 401) {
          history.push("/login");
        }
      });
  };

  // React.useEffect(() => {

  //     if (packageId) {
  //         packageSelect(packageId);
  //     }

  // }, [packageId]);

  useEffect(() => {
    if (
      selectedJob &&
      homeDistrict &&
      (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව")
    ) {
      getSriDistricts({ language: t("langKey") })
        .then((res) => {
          var mobile22 = res.data?.filter(function (el) {
            return el.name_en === homeDistrict;
          });

          sethomeDistrictId2(mobile22.length > 0 ? mobile22[0]?.id : 0);

          // setnewSubs(newSubArray.filter((u) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)))
        })
        .catch((error) => {
          console.log("Error", error.response);
        });
    }
  }, [selectedJob, homeDistrict, country]);

  React.useEffect(() => {
    listDistricts();
    // getCurrentUser();
  }, [lan, userCountry]);

  React.useEffect(() => {
    listDistrictsPro();
    // getCurrentUser();
  }, [country, lan]);

  React.useEffect(() => {
    // listDistricts();
    getCurrentUser();
  }, []);

  React.useEffect(() => {
    if (homeDistrictId !== 0) {
      listHomecities();
    }
  }, [homeDistrictId, lan, userCountry]);

  React.useEffect(() => {
    if (homeDistrictId2 !== 0) {
      listHomecitiesPro();
    }
  }, [homeDistrictId2, country, lan]);

  // const listDistricts = () => {
  //     getDistricts()
  //         .then((res) => {
  //             console.log("districts>>>", res);
  //             setallHDistricts(res.data);
  //             // setallODistricts(res.data);
  //         })
  //         .catch((error) => {
  //             console.log("Error", error.response);
  //             // setOpenToast(true);
  //             // setColor(COLORS.Orange);
  //             // setAlertClass("error");
  //             // setMsg(error.response.data.message);
  //         });
  // };
  // const listHomecities = () => {
  //     getCities(homeDistrict)
  //         .then((res) => {
  //             console.log("cities>>>", res.data.records);
  //             setallHCities(res.data.records);
  //         })
  //         .catch((error) => {
  //             console.log("Error", error.response);
  //             setallHCities([])
  //             // setOpenToast(true);
  //             // setColor(COLORS.Orange);
  //             // setAlertClass("error");
  //             // setMsg(error.response.data.message);
  //         });
  // };

  const listDistricts = () => {
    if (country === "Australia") {
      getDistricts()
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistricts(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව") {
      getSriDistricts({ language: t("langKey") })
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistricts(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      setallHDistricts([]);
    }
  };

  const listHomecities = () => {
    if (country === "Australia") {
      getCities(homeDistrict)
        .then((res) => {
          console.log("cities>>>", res.data);
          setallHCities(res.data.records);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCities([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව") {
      getSriCities(homeDistrictId, t("langKey"))
        .then((res) => {
          console.log("cities>>>", res.data);

          const subArr = res.data?.map((d) => {
            return {
              ...d,
              fields: {
                scc_name: d.name_en,
                scc_code: d.postcode
              }
            };
          });

          setallHCities(subArr);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCities([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const listDistrictsPro = () => {
    if (country === "Australia") {
      getDistricts()
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistrictspro(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව") {
      getSriDistricts({ language: t("langKey") })
        .then((res) => {
          console.log("districts>>>", res);
          setallHDistrictspro(res.data);
          // sethomeDistrict(res.data[0]?.name_en)
          // sethomeDistrictId(res.data[0]?.id);
          // seterrhomeDistrict(false);
          // setallODistricts(res.data);
        })
        .catch((error) => {
          console.log("Error", error.response);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      setallHDistrictspro([]);
    }
  };

  const listHomecitiesPro = () => {
    if (country === "Australia") {
      getCities(homeDistrict)
        .then((res) => {
          console.log("cities>>>", res.data);
          setallHCitiespro(res.data.records);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCitiespro([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else if (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව") {
      getSriCities(homeDistrictId2, t("langKey"))
        .then((res) => {
          console.log("cities>>>", res.data);

          const subArr = res.data?.map((d) => {
            return {
              ...d,
              fields: {
                scc_name: d.name_en,
                scc_code: d.postcode
              }
            };
          });

          setallHCitiespro(subArr);
          // sethomeCity(res.data.records[0].fields.scc_name)
          // sethomepostCode(res.data.records[0].fields.scc_code)
          // seterrhomeCity(false);
          // seterrhomepostCode(false);
        })
        .catch((error) => {
          console.log("Error", error.response);
          setallHCitiespro([]);
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    }
  };

  const handleChangeHdist = (e, value) => {
    if (value !== null) {
      sethomeDistrict(value.name_en);
      seterrhomeDistrict(false);
      sethomeDistrictId2(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId2(0);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    // setnewSubs([])

    // const filtered = newSubArray.filter((u) => u.state === value.id)

    // const perChunk = 700

    // const result = filtered.reduce((filtered, item, index) => {
    //     const chunkIndex = Math.floor(index / perChunk)

    //     if (!filtered[chunkIndex]) {
    //         filtered[chunkIndex] = [] // start a new chunk
    //     }

    //     filtered[chunkIndex].push(item)

    //     return filtered
    // }, [])

    // console.log("<<<<<<<", result)

    // for (let i = 0; i < result.length - 1; i++) {

    //     let item = result[i];

    //     setTimeout(() => {
    //         setnewSubs(existing => [...existing, ...item]);
    //     }, i === 0 ? 0 : (2000));
    // }
  };
  const handleChangeHcity = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      // sethomeCity(value);
      // sethomepostCode(value.postcode);
      sethomeCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterrhomeCity(false);
      // seterrhomepostCode(false);
    } else {
      sethomeCity("");
      // sethomepostCode("");
    }

    console.log("city>>", value);
  };

  const handleChangeHdistUser = (e, value) => {
    if (value !== null) {
      setuserDistrict(value.name_en);
      seterruserDistrict(false);
      sethomeDistrictId(value.id);
    } else {
      sethomeDistrict("");
      sethomeDistrictId(0);
    }

    console.log("state>>", value);
    // setnewSubs(newSubArray.filter((u) => u.state === value.id))

    // setnewSubs([])

    // const filtered = newSubArray.filter((u) => u.state === value.id)

    // const perChunk = 700

    // const result = filtered.reduce((filtered, item, index) => {
    //     const chunkIndex = Math.floor(index / perChunk)

    //     if (!filtered[chunkIndex]) {
    //         filtered[chunkIndex] = [] // start a new chunk
    //     }

    //     filtered[chunkIndex].push(item)

    //     return filtered
    // }, [])

    // console.log("<<<<<<<", result)

    // for (let i = 0; i < result.length - 1; i++) {

    //     let item = result[i];

    //     setTimeout(() => {
    //         setnewSubs(existing => [...existing, ...item]);
    //     }, i === 0 ? 0 : (2000));
    // }
  };
  const handleChangeHcityUser = (e, value) => {
    if (value !== null) {
      // console.log(">><<", value.fields.scc_name)
      // sethomeCity(value);
      // sethomepostCode(value.postcode);
      setuserCity(value.fields.scc_name);
      // sethomepostCode(value.postcode);
      seterruserCity(false);
      // seterrhomepostCode(false);
    } else {
      setuserCity("");
      // sethomepostCode("");
    }

    console.log("city>>", value);
  };
  const [errFamilyBackgroundLength, setErrFamilyBackgroundLength] =
  useState(false);
  const saveJob = async (e, type = "next") => {
    // onCloseModalQuickView();

    e.preventDefault();

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    // const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail\.com|yahoo\.com)$/;

    if (!title) {
      seterrtitle(true);
    }

    if (!gender) {
      seterrgender(true);
    }

    if (!horoscopStatus) {
      seterrhoroscopStatus(true);
    }

    if (!age) {
      seterrage(true);
    }

    if (!cast) {
      seterrcast(true);
    }

    if (!race) {
      seterrrace(true);
    }

    if (!religion) {
      seterrreligion(true);
    }

    // if (!height) {
    //     seterrheight(true);
    // }

    if (!feet) {
      seterrheight(true);
    }

    // if (!inch) {
    //     seterrheight(true);
    // }

    if (!educationStatus) {
      seterreducationStatus(true);
    }

    if (!occupation) {
      seterrocupation(true);
    }

    if (!marriageStatus) {
      seterrmarriageStatus(true);
    }

    if (!mobile) {
      if (!phoneRegex.test(mobile)) {
        if (mobile === "") {
          // seterrmobile("This field is required");
        } else {
          seterrmobile("Please enter valid number");
        }
      }
    }

    if (!whatsAppNo) {
      if (!phoneRegex.test(whatsAppNo)) {
        if (whatsAppNo !== "") {
          seterrwhatsAppNo("Please enter valid number");
        }
      }
    }

    if (!country) {
      seterrcountry(true);
    }

    if (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව") {
      if (!homeDistrict) {
        seterrhomeDistrict(true);
      }
      if (!homeCity) {
        seterrhomeCity(true);
      }
    }
    if (country !== "Sri Lanka" && country !== "ශ්‍රී ලංකාව") {
      if (!email) {
        seterremail("This field is required");
      }
    } else {
      if (!email) {
        seterremail(false);
      }
    }

    if (!phoneRegex.test(userContact)) {
      if (userContact === "") {
        seterruserContact("This field is required");
      } else {
        seterruserContact("Please enter valid number");
      }
    }

    if (!userFname) {
      seterruserFname(true);
    }

    if (!userCountry) {
      seterruserCountry(true);
    }

    if (!familyBackground) {
      seterrfamilyBackground(true);
    }

    // if (familyBackground.length < 100) {
    //   setErrFamilyBackgroundLength(true);
    // } else {
    //   setErrFamilyBackgroundLength(false);
    // }
  
    if (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව") {
      if (!userDistrict) {
        seterruserDistrict(true);
      }
      if (!userCity) {
        seterruserCity(true);
      }
    }

    if (email) {
      if (!emailRegex.test(email)) {
        seterremail("Please enter valid email");
      }
    }

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    let objUser = {
      firstName: userFname,
      lastName: currUser.lastName === null ? "" : currUser.lastName,
      supplierCategory:
        currUser.supplierCategory === null ? "" : currUser.supplierCategory,
      businessName: currUser.businessName === null ? "" : currUser.businessName,
      businessRegistrationNumber:
        currUser.businessRegistrationNumber === null
          ? ""
          : currUser.businessRegistrationNumber,
      establishedYear:
        currUser.establishedYear === null ? "" : currUser.establishedYear,
      website: currUser.website === null ? "" : currUser.website,
      country: userCountry,
      contactNumber: userContact,
      contactNumber2:
        currUser.contactNumber2 === null ? "" : currUser.contactNumber2,
      email: currUser.email === null ? "" : currUser.email,
      contactName: currUser.contactName === null ? "" : currUser.contactName,
      about: currUser.about === null ? "" : currUser.about,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: userAddress,
      homeCity: userCity,
      homeDistrict: userDistrict,
      homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
      note: currUser.note === null ? "" : currUser.note,
      cover: !currUser.cover ? "/uploads/cover/cover.jpg" : currUser.cover,
      avatar: !currUser.avatar ? "/uploads/avatar/avatar.jpg" : currUser.avatar,
      logo: currUser.logo === null ? "" : currUser.logo,
      paymentGateway:
        currUser.paymentGateway === null ? "" : currUser.paymentGateway,
      userType: currUser.userType === null ? "" : currUser.userType,
      clientId: currUser.clientId === null ? "" : currUser.clientId,
      secretId: currUser.secretId === null ? "" : currUser.secretId,
      deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
      salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
      password: "",
      serviceProviderImages: !currUser.serviceProviderImages
        ? ""
        : JSON.parse(currUser.serviceProviderImages),
      serviceProvierVideos: !currUser.serviceProvierVideos
        ? ""
        : currUser.serviceProvierVideos,
      cleaningTypeId:
        currUser.cleaningTypeId === null ? "" : currUser.cleaningTypeId,
      shippitKey: currUser.shippitKey === null ? "" : currUser.shippitKey,
      websiteRequest:
        currUser.websiteRequest === null ? 0 : currUser.websiteRequest,
      refundPolicyDescription: currUser?.refundPolicyDescription
        ? currUser?.refundPolicyDescription
        : "",
      refundPolicyDocument: currUser?.refundPolicyDocument
        ? currUser?.refundPolicyDocument
        : "",
      deliveryPolicyDescription: currUser?.deliveryPolicyDescription
        ? currUser?.deliveryPolicyDescription
        : "",
      deliveryPolicyDocument: currUser?.deliveryPolicyDocument
        ? currUser?.deliveryPolicyDocument
        : "",

      publicLiabilityInsurance:
        currUser.publicLiabilityInsurance === null
          ? 0
          : currUser.publicLiabilityInsurance,
      IndemnityInsurance:
        currUser.IndemnityInsurance === null ? 0 : currUser.IndemnityInsurance,
      workCoverInsurance:
        currUser.workCoverInsurance === null ? 0 : currUser.workCoverInsurance,
      workersCompensationInsurance:
        currUser.workersCompensationInsurance === null
          ? 0
          : currUser.workersCompensationInsurance,
      policeClearanceCertificate:
        currUser.policeClearanceCertificate === null
          ? 0
          : currUser.policeClearanceCertificate,
      workingWithChildren:
        currUser.workingWithChildren === null
          ? 0
          : currUser.workingWithChildren,

      policeClearanceCertificateDoc: currUser?.policeClearanceCertificateDoc
        ? currUser?.policeClearanceCertificateDoc
        : "",
      workingWithChildrenDoc: currUser?.workingWithChildrenDoc
        ? currUser?.workingWithChildrenDoc
        : "",
      issuedDate: currUser?.issuedDate ? currUser?.issuedDate : "",
      expiryDate: currUser?.expiryDate ? currUser?.expiryDate : "",
      mainId: currUser?.mainId ? currUser?.mainId : "",
      subId: currUser?.subId ? currUser?.subId : "",
      title: title
    };
    console.log("obj>>>>", objUser);

    let obj = {
      // jobId: orderMyNo,
      name: title,
      userId: GetUserId(),
      gender: gender,
      horoscopStatus: horoscopStatus,
      age: age,
      cast: cast,
      race: race,
      religion: religion,
      height: inch ? `${feet} ${inch}'` : `${feet}`,
      educationStatus: educationStatus,
      occupation: occupation,
      marriageStatus: marriageStatus,
      familyBackground: familyBackground,
      contactNo: mobile,
      whatsAppNo: whatsAppNo,
      mainEmail: email,
      secondaryEmail: email2,
      document: images,
      currentResidesCountry: country,
      city: homeCity,
      // duration: duration,
      // hourlyRate: rate.toString(),
      // currency: currency,
      // expiryDate: moment(expire).format("YYYY-MM-DD"),
      district: homeDistrict,
      createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
      feet: feet,
      inch: inch
      // jobType: jobType,
      // packageUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      // packageDetails: obj2,
      // duties: editorData,
      // oldPkg: oldPkg
    };

    console.log("obj>>>>", obj);
    console.log("cout", homeCity);

    if (
      gender &&
      age &&
      horoscopStatus &&
      cast &&
      race &&
      religion &&
      feet &&
      educationStatus &&
      occupation &&
      marriageStatus &&
      // familyBackground.length > 100 &&
      (whatsAppNo ? phoneRegex.test(whatsAppNo) : true) &&
      (email ? emailRegex.test(email) : true) &&
      (mobile ? phoneRegex.test(mobile) : true) &&
      country &&
      (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව"
        ? homeDistrict
        : true) &&
      (country === "Sri Lanka" || country === "ශ්‍රී ලංකාව"
        ? homeCity
        : true) &&
      (country !== "Sri Lanka" && country !== "ශ්‍රී ලංකාව" ? email : true)
    ) {
      console.log("ssseeeer");
      if (!selectedJob) {
        setlOpen(true);
        console.log("ssssss");
        postProfile(obj)
          .then((res) => {
            setlOpen(false);
            // if (res.data.includes('stripe')) {
            //     window.location.href = res.data;
            //     return;
            // }
            // setlOpen(false);
            if (type !== "next") {
              // history.push("/profile?selectPage=ads");
              // window.scrollTo({
              //     top: 0,
              //     behavior: "smooth",
              // });

              setShowModalQuickView(true);
            } else {
              setbOpen(true);
              setbMsg(t("pSuccess"));
              setbStatus("success");
              setShowModalQuickView(true);
            }

            // addToast("Job successfully added", "success");
            // settitle("");
            setjobType("");
            setcountry("");
            setdescription("");
            sethomeDistrict("");
            sethomeCity("");
            setduration("");
            setrate("");
            setImages([]);
            setKeywords("");
            setexpire("");
            setcurrency("");
            setEditorData("<br/><br/><br/><br/><br/>");

            setgender("");
            sethoroscopStatus("");
            setage("");
            setcast("");
            setrace("");
            setreligion("");
            setheight("");
            seteducationStatus("");
            setoccupation("");
            setmarriageStatus("");
            setfamilyBackground("");
            setgender("");
            setmobile("");
            setmobile2("");
            setwhatsAppNo("");
            setemail("");
            setemail2("");
            setfeet("");
            setinch("");

            onCloseModalQuickView();

            setShowModalQuickView3(true);
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response.status);
            if (error.response.status === 410) {
              addToast(error.response.data.message, "error");
            } else {
              setbOpen(true);
              setbMsg(error.response.data.message);
              setbStatus("warning");
            }

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      } else {
        setlOpen(true);

        UpdateProfiles(obj, selectedJob?.id)
          .then((res) => {
            setlOpen(false);
            if (res.data.includes("stripe")) {
              window.location.href = res.data;
              return;
            } else {
              setbOpen(true);
              setbMsg(t("adUp"));
              setbStatus("success");
              setdisEdit(true);
            }

            // addToast("Job successfully updated ", "success");

            // settitle("");
            // setjobType("");
            // setcountry("");
            // setdescription("");
            // sethomeDistrict("");
            // sethomeCity("");
            // setduration("");
            // setrate("");
            // setImages([]);
            // setKeywords("");
            // setexpire("");
            // setcurrency("");

            // setgender("")
            // sethoroscopStatus("")
            // setage("")
            // setcast("")
            // setrace("")
            // setreligion("")
            // setheight("")
            // seteducationStatus("")
            // setoccupation("")
            // setmarriageStatus("")
            // setfamilyBackground("")
            // setgender("")
            // setmobile("")
            // setmobile2("")
            // setwhatsAppNo("")
            // setemail("")
            // setemail2("")
            onCloseModalQuickView();
            // setOpenToast(true);
            // setColor(COLORS.Primary);
            // setAlertClass("success");
            // setMsg("Successfully updated");
            // getCurrentUser();
            // seteDisable(true);
            // getUser();

            // history.push("/");
            // window.location.reload();
          })
          .catch((error) => {
            setlOpen(false);
            console.log("Error", error.response);
            setbOpen(true);
            setbMsg(error.response.data.message);
            setbStatus("warning");

            // addToast(error.response.data.message, "warning");
            // setOpenToast(true);
            // setColor(COLORS.Orange);
            // setAlertClass("error");
            // setMsg(error.response.data.message);
          });
      }
    } else {
      // handleScroll();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const saveOwner = async (e, type = "next") => {
    // onCloseModalQuickView();

    e.preventDefault();

    const phoneRegex =
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if (!title) {
      seterrtitle(true);
    }

    if (!phoneRegex.test(userContact)) {
      if (userContact === "") {
        seterruserContact("This field is required");
      } else {
        seterruserContact("Please enter valid number");
      }
    }

    if (!userFname) {
      seterruserFname(true);
    }

    if (!userType) {
      seterruserType(true);
    }

    if (!userCountry) {
      seterruserCountry(true);
    }

    if (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව") {
      if (!userDistrict) {
        seterruserDistrict(true);
      }
      if (!userCity) {
        seterruserCity(true);
      }
    }

    // if (!duration) {
    //     seterrduration(true);
    // }
    // if (!description) {
    //     seterrdescription(true);
    // }
    // if (!currency || !rate) {
    //     seterrrate(true);
    // }
    // if (!expire) {
    //     seterrexpire(true);
    // }
    // if (!packageId) {
    //     seterrpackages(true);
    // }
    // if (!Keywords) {
    //     seterrKeywords(true);
    // }
    // if (images.length === 0) {
    //     seterrImages(true);
    // }

    // console.log(">>>>>>>>", _.filter(Keywords.split(" ")))

    console.log(">>>>>>", images);

    let obj2 = {};

    let item = packageId;

    if (item === "1") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "FREE",
        packageDetails: [
          {
            packagePrice: "FREE",
            qty: 1,
            packageName: "FREE" + " " + "Subscription",
            days: 7
          }
        ],
        packageId: 1,
        userId: currUser?.id
      };
    } else if (item === "2") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "15",
        packageDetails: [
          {
            packagePrice: "15",
            qty: 1,
            packageName: "Basic" + " " + "Subscription",
            days: 28
          }
        ],
        packageId: 2,
        userId: currUser?.id
      };
    } else if (item === "3") {
      obj2 = {
        email: currUser?.email,
        amountPayable: "30",
        packageDetails: [
          {
            packagePrice: "30",
            qty: 1,
            packageName: "Plus" + " " + "Subscription",
            days: 84
          }
        ],
        packageId: 3,
        userId: currUser?.id
      };
    }

    let orderMyNo = 100000;

    // await getJobDetails()
    //     .then((res) => {
    //         orderMyNo = parseInt(res.data[res.data.length - 1]?.id) + 1;
    //     })
    //     .catch((error) => {
    //         console.error("order history error", error.response);
    //     });

    let objUser = {
      firstName: userFname,
      lastName: currUser.lastName === null ? "" : currUser.lastName,
      supplierCategory:
        currUser.supplierCategory === null ? "" : currUser.supplierCategory,
      businessName: currUser.businessName === null ? "" : currUser.businessName,
      businessRegistrationNumber:
        currUser.businessRegistrationNumber === null
          ? ""
          : currUser.businessRegistrationNumber,
      establishedYear:
        currUser.establishedYear === null ? "" : currUser.establishedYear,
      website: currUser.website === null ? "" : currUser.website,
      country: userCountry,
      contactNumber: userContact,
      contactNumber2:
        currUser.contactNumber2 === null ? "" : currUser.contactNumber2,
      email: currUser.email === null ? "" : currUser.email,
      contactName: currUser.contactName === null ? "" : currUser.contactName,
      about: currUser.about === null ? "" : currUser.about,
      homeNumber: currUser.homeNumber === null ? "" : currUser.homeNumber,
      homeAddress: userAddress,
      homeCity: userCity,
      homeDistrict: userDistrict,
      homepostCode: currUser.homepostCode === null ? "" : currUser.homepostCode,
      note: currUser.note === null ? "" : currUser.note,
      cover: !currUser.cover ? "/uploads/cover/cover.jpg" : currUser.cover,
      avatar: !currUser.avatar ? "/uploads/avatar/avatar.jpg" : currUser.avatar,
      logo: currUser.logo === null ? "" : currUser.logo,
      paymentGateway:
        currUser.paymentGateway === null ? "" : currUser.paymentGateway,
      userType: currUser.userType === null ? "" : currUser.userType,
      clientId: currUser.clientId === null ? "" : currUser.clientId,
      secretId: currUser.secretId === null ? "" : currUser.secretId,
      deliveryFee: currUser.deliveryFee === null ? "" : currUser.deliveryFee,
      salesCount: currUser.salesCount === null ? "" : currUser.salesCount,
      password: "",
      serviceProviderImages: !currUser.serviceProviderImages
        ? ""
        : JSON.parse(currUser.serviceProviderImages),
      serviceProvierVideos: !currUser.serviceProvierVideos
        ? ""
        : currUser.serviceProvierVideos,
      cleaningTypeId:
        currUser.cleaningTypeId === null ? "" : currUser.cleaningTypeId,
      shippitKey: currUser.shippitKey === null ? "" : currUser.shippitKey,
      websiteRequest:
        currUser.websiteRequest === null ? 0 : currUser.websiteRequest,
      refundPolicyDescription: currUser?.refundPolicyDescription
        ? currUser?.refundPolicyDescription
        : "",
      refundPolicyDocument: currUser?.refundPolicyDocument
        ? currUser?.refundPolicyDocument
        : "",
      deliveryPolicyDescription: currUser?.deliveryPolicyDescription
        ? currUser?.deliveryPolicyDescription
        : "",
      deliveryPolicyDocument: currUser?.deliveryPolicyDocument
        ? currUser?.deliveryPolicyDocument
        : "",

      publicLiabilityInsurance:
        currUser.publicLiabilityInsurance === null
          ? 0
          : currUser.publicLiabilityInsurance,
      IndemnityInsurance:
        currUser.IndemnityInsurance === null ? 0 : currUser.IndemnityInsurance,
      workCoverInsurance:
        currUser.workCoverInsurance === null ? 0 : currUser.workCoverInsurance,
      workersCompensationInsurance:
        currUser.workersCompensationInsurance === null
          ? 0
          : currUser.workersCompensationInsurance,
      policeClearanceCertificate:
        currUser.policeClearanceCertificate === null
          ? 0
          : currUser.policeClearanceCertificate,
      workingWithChildren:
        currUser.workingWithChildren === null
          ? 0
          : currUser.workingWithChildren,

      policeClearanceCertificateDoc: currUser?.policeClearanceCertificateDoc
        ? currUser?.policeClearanceCertificateDoc
        : "",
      workingWithChildrenDoc: currUser?.workingWithChildrenDoc
        ? currUser?.workingWithChildrenDoc
        : "",
      issuedDate: currUser?.issuedDate ? currUser?.issuedDate : "",
      expiryDate: currUser?.expiryDate ? currUser?.expiryDate : "",
      mainId: currUser?.mainId ? currUser?.mainId : "",
      subId: currUser?.subId ? currUser?.subId : "",
      title: title,
      accType: userType
    };
    console.log("obj>>>>", objUser);

    if (
      title &&
      userType &&
      userCountry &&
      (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව"
        ? userDistrict
        : true) &&
      (userCountry === "Sri Lanka" || userCountry === "ශ්‍රී ලංකාව"
        ? userCity
        : true) &&
      // userAddress &&
      userContact &&
      phoneRegex.test(userContact) &&
      userFname
    ) {
      setlOpen(true);

      EditUserQuery(objUser)
        .then((res) => {
          setlOpen(false);
          // addToast("Successfully updated", "success");
          // setOpenToast(true);
          // setColor(COLORS.Primary);
          // setAlertClass("success");
          // setMsg("Successfully updated");
          // setImgSrc("");
          // setImgSrcavatar("");
          // setCrop({ x: 0, y: 0 });
          // setZoom(1);
          // setCroppedArea(null);

          setbOpen(true);
          setbMsg(t("adUp"));
          setbStatus("success");
          setdisEdit(true);
          getCurrentUser();
          // seteDisable(true);

          // history.push("/");
          // window.location.reload();
        })
        .catch((error) => {
          setlOpen(false);
          console.log("Error", error.response);
          setbOpen(true);
          setbMsg(error.response.data.message);
          setbStatus("warning");
          // addToast(error.response.data.message, "warning");
          // setOpenToast(true);
          // setColor(COLORS.Orange);
          // setAlertClass("error");
          // setMsg(error.response.data.message);
        });
    } else {
      // scrollElement();
      setbOpen(true);
      setbMsg("Required fields are missing and add missing fields.");
      setbStatus("warning");
    }
  };

  const getCurrentUser = () => {
    CurrentUser()
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          const obj = {
            id: cData.id,
            email: cData?.email,
            firstName: cData?.firstName,
            lastName: cData?.lastName,
            phone: cData?.contactNumber
          };
          //   this.setState({
          //     firstName: cData?.firstName,
          //     lastName: cData?.lastName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          setcurrUser(cData);

          setuserFname(cData?.firstName);
          setuserAddress(cData?.homeAddress);
          setuserContact(cData?.contactNumber);
          settitle(cData?.title);
          setuserCountry(cData?.country ? cData?.country : "");
          setuserDistrict(cData?.homeDistrict ? cData?.homeDistrict : "");
          setuserCity(cData?.homeCity ? cData?.homeCity : "");
          setuserType(cData?.accType);

          if (!cData?.title) {
            setdisEdit(false);
          }

          if (
            cData?.homeDistrict &&
            (cData?.country === "Sri Lanka" || cData?.country === "ශ්‍රී ලංකාව")
          ) {
            getSriDistricts({ language: t("langKey") })
              .then((res) => {
                var mobile22 = res.data?.filter(function (el) {
                  return el.name_en === cData?.homeDistrict;
                });

                sethomeDistrictId(mobile22.length > 0 ? mobile22[0]?.id : 0);

                // setnewSubs(newSubArray.filter((u) => u.state === (mobile22.length > 0 ? mobile22[0]?.id : 0)))
              })
              .catch((error) => {
                console.log("Error", error.response);
              });
          }

          // setpackageId(cData.packageId ? cData.packageId : "");

          // if (!selectedJob) {

          //     if (cData.packageId === "1") {

          //         let day = moment().add(7, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } if (cData.packageId === "2") {

          //         let day = moment().add(28, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     } else if (cData.packageId === "3") {

          //         let day = moment().add(84, "days")
          //         day = moment(day).format("YYYY-MM-DD")

          //         console.log(">>>>>>>", moment(day).toDate())
          //         setexpire(moment(day).toDate());

          //     }
          // }

          localStorage.setItem(
            `${window.location.hostname}.profile`,
            JSON.stringify(obj)
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  function CustomInput({ onFocus, value, onChange }) {
    return (
      <div className="mt-1.5 flex">
        <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
          <i className="text-xl las la-calendar"></i>
        </span>
        <Input
          readOnly
          className="!rounded-l-none"
          placeholder="DD-MM-YYYY"
          onFocus={onFocus}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  }

  useEffect(() => {
    if (bOpen === true) {
      setTimeout(() => {
        setbOpen(false);
      }, 10000);
    }
  }, [bOpen]);

  const [symbolError, setSymbolError] = useState(false); // New state for symbol error

  const handleInputChange = (e) => {
    const input = e.target.value;
    const regex = /^[a-zA-Z]*$/; // Alphabetic characters only

    if (regex.test(input)) {
      setuserFname(input); // Set value if valid
      seterruserFname(false);
      setSymbolError(false); // Reset error if valid
    } else {
      setSymbolError(true); // Show error if invalid characters
    }
  };

  

  return (
    <>
      <LoadingSpinner open={lOpen} />

      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        selectedJob={""}
      />

      {!modal && !selectedJob && (
        // <div className="flex justify-center w-full mt-12">

        //     <span className="block text-3xl font-bold flex justify-center w-full text-center sm:max-w-[50%]">
        //         {t('pCreate')}
        //     </span>

        // </div>

        <></>
      )}

      <div className="w-full flex justify-center px-3 lg:px-0">
        <div
          className={`w-full ${modal ? "" : "md:w-9/12 lg:w-8/12 xl:w-[50%]"} ${
            modal ? "" : "py-4  mt-4 "
          }  `}
        >
          {/* {
                        selectedJob &&

                        <span className="flex justify-start text-lg font-bold">{t("proHead")} {index + 1}</span>

                    } */}

          {!selectedJob && owner && !modal && (
            <div className="bg-[#F4F4F4] pb-1 sm:px-4 sm:pb-4 mt-0">
              <>
                <div className="flex justify-end px-3 sm:px-1 pt-4">
                  {disEdit === true ? (
                    <Tooltip
                      title={"Edit"}
                      placement="top"
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <div className="ml-2 bg-[#003D35] p-1 rounded-full flex items-center justify-center text-white">
                        <i
                          className={`las la-pencil-alt text-xl text-left  cursor-pointer`}
                          onClick={() => {
                            // if (item.packageExpire === 0) {
                            // console.log("item>>>>", item)
                            // setselectedJob(item);
                            // setShowModalQuickView(true);
                            // }
                            setdisEdit(false);
                          }}
                        ></i>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={"Cancel"}
                      placement="top"
                      TransitionComponent={Zoom}
                      arrow
                    >
                      <div className="ml-2 bg-[#003D35] p-1 rounded-full flex items-center justify-center text-white">
                        <i
                          className={`las la-times text-xl text-left cursor-pointer`}
                          onClick={() => {
                            // if (item.packageExpire === 0) {
                            // console.log("item>>>>", item)
                            // setselectedJob(item);
                            // setShowModalQuickView(true);
                            // }
                            setdisEdit(true);
                          }}
                        ></i>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className="justify-start w-full mt-0 px-5 ">
                  <div>
                    <span className="block text-xl font-bold flex justify-center sm:justify-start w-full text-center text-[#003d35]">
                      {/* {selectedJob ? "Edit Profile" : "Details of the Proposal Seeker"} */}
                      {t("pTitleAcc")}
                    </span>

                    {
                      // !selectedJob &&

                      <div className="flex justify-start">
                        <span className="text-xs flex justify-center sm:justify-start w-full text-center sm:text-left mt-1 mb-6 text-[#003d35]">
                          {t("pDescAcc")}
                        </span>
                      </div>
                    }
                  </div>

                  <div className="grid gird-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-5">
                    <div ref={ref} className="sm:col-span-2">
                      <Label className="text-sm">{t("ownerType")} *</Label>
                      {/* <div className="w-full flex"> */}
                      {/* <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"

                onChange={(e) => {
                    setcurrency(e.target.value);
                    seterrrate(false);
                }}
                value={currency}
            >
                <option value={"Mr"}>Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="">Other</option>

            </Select> */}
                      {/* <Input className="mt-1.5"
                rounded="rounded-r-lg"
                placeholder={t('pTitle')}
                onChange={(e) => {
                    settitle(e.target.value);
                    seterrtitle(false);
                }}
                value={title}
            /> */}
                      <Autocomplete
                        disabled={disEdit}
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        // defaultValue={{ value: category }}
                        value={userType ? userType : ""}
                        // id="combo-box-demo"
                        options={lan === "English" ? TYPEarr : TYPEarrSi}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => {
                          setuserType(value);
                          seterruserType(false);

                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input
                              {...params.inputProps}
                              className=""
                              placeholder={t("ownerType")}
                            />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            fontFamily:
                              t("langKey") === "Si" ? "NotoSans" : "Inter"
                            // border: '1px solid white'
                          }
                        }}
                      />
                      {/* </div> */}
                      {erruserType && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                    </div>

                    <div>
                      <Label className="text-sm">{t("pSalu")} *</Label>
                      {/* <div className="w-full flex"> */}
                      {/* <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"

                onChange={(e) => {
                    setcurrency(e.target.value);
                    seterrrate(false);
                }}
                value={currency}
            >
                <option value={"Mr"}>Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="">Other</option>

            </Select> */}
                      {/* <Input className="mt-1.5"
                rounded="rounded-r-lg"
                placeholder={t('pTitle')}
                onChange={(e) => {
                    settitle(e.target.value);
                    seterrtitle(false);
                }}
                value={title}
            /> */}
                      <Autocomplete
                        disabled={disEdit}
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        // defaultValue={{ value: category }}
                        value={title ? title : ""}
                        // id="combo-box-demo"
                        options={lan === "English" ? TITarr : TITarrSi}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => {
                          settitle(value);
                          seterrtitle(false);

                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input
                              {...params.inputProps}
                              className=""
                              placeholder={t("pSalu")}
                            />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            fontFamily:
                              t("langKey") === "Si" ? "NotoSans" : "Inter"
                            // border: '1px solid white'
                          }
                        }}
                      />
                      {/* </div> */}
                      {errtitle && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                    </div>

                    {/* <div>
                                            <Label className="text-sm">{t('pAdd')}</Label>
                                            <Input className="mt-1.5"
                                                placeholder={t('pAdd')}
                                                // readOnly
                                                onChange={(e) => {
                                                    setuserAddress(e.target.value);
                                                    seterruserAddress(false);
                                                }}
                                                value={userAddress}
                                            />
                                            {erruserAddress && (
                                                <div className="text-red-600 text-sm text-right mt-2">
                                                    This field is required
                                                </div>
                                            )}
                                        </div> */}

                    <div>
                      <Label className="text-sm">{t("pAcc")} *</Label>
                      <Input
                        className="mt-1.5"
                        disabled={disEdit}
                        placeholder={t("pAcc")}
                        onChange={handleInputChange}
                        value={userFname}
                      />
                      {erruserFname && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                      {symbolError && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          No symbols allowed. Please use only letters and
                          numbers.
                        </div>
                      )}
                    </div>

                    <div>
                      <Label className="text-sm">{t("fCountry")} *</Label>
                      <Autocomplete
                        disabled={disEdit}
                        className="mt-1.5"
                        // className="form-group"
                        // className="border-neutral-200"
                        disablePortal
                        defaultValue={userCountry}
                        value={userCountry}
                        // id="combo-box-demo"
                        // options={[lan === "English" ? "Sri Lanka" : "ශ්‍රී ලංකාව"]}
                        options={COUNTArr}
                        getOptionLabel={(option) => option}
                        onChange={(e, value) => {
                          setuserCountry(value);
                          seterruserCountry(false);
                          setuserDistrict("");
                          setuserCity("");
                          // setCountry(value);
                        }}
                        // sx={{
                        //   // width: "80vw",
                        //   background:"transparent",
                        //   borderRadius: "20px",
                        //   // border: "none",
                        //   // borderColor: "transparent",

                        //   "& .MuiInputBase-input": {
                        //     height: 15,
                        //     // border: "none",
                        //     fontSize: "14px",
                        //     fontFamily: "'Jost', sans-serif",
                        //     // borderColor: "transparent",
                        //   },
                        //   //view
                        //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                        //     // borderColor: "transparent",
                        //   },

                        //   "&:hover .MuiOutlinedInput-input": {
                        //     color: "black",
                        //   },
                        //   "&:hover .MuiInputLabel-root": {
                        //     color: COLORS.GrayBackground,
                        //   },
                        //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        //   {
                        //     // borderColor: "transparent",
                        //   },
                        //   ".MuiAutocomplete-root fieldset:hover": {
                        //     color: "transparent",
                        //     // borderColor: "transparent",
                        //   },
                        // }}
                        renderInput={(params) => (
                          <div ref={params.InputProps.ref}>
                            <Input
                              {...params.inputProps}
                              className=""
                              placeholder={t("fCountry")}
                            />
                          </div>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "150px",
                            fontSize: "14px",
                            fontFamily:
                              t("langKey") === "Si" ? "NotoSans" : "Inter"
                            // border: '1px solid white'
                          }
                        }}
                      />

                      {erruserCountry && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          This field is required
                        </div>
                      )}
                    </div>

                    {(userCountry === "Sri Lanka" ||
                      userCountry === "ශ්‍රී ලංකාව") && (
                      <>
                        <div>
                          <Label className="text-sm">{t("fDis")} *</Label>

                          <Autocomplete
                            disabled={disEdit}
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            defaultValue={{ name_en: userDistrict }}
                            value={{ name_en: userDistrict }}
                            // id="combo-box-demo"
                            options={allHDistricts}
                            getOptionLabel={(option) => option.name_en}
                            onChange={(event, value) => {
                              handleChangeHdistUser(event, value);
                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className=""
                                  placeholder={t("fDis")}
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px",
                                fontFamily:
                                  t("langKey") === "Si" ? "NotoSans" : "Inter"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {erruserDistrict && (
                            <div className="text-red-600 text-sm text-right mt-2">
                              This field is required
                            </div>
                          )}
                        </div>

                        <div>
                          <Label className="text-sm">{t("fCity")} *</Label>

                          {/* <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ fields: { scc_name: homeCity } }}
                                // value={{ fields: { scc_name: homeCity } }}
                                // // id="combo-box-demo"
                                // options={allHCities}
                                // getOptionLabel={(option) => option?.fields?.scc_name}
                                defaultValue={{ locality: homeCity }}
                                value={{ locality: homeCity }}

                                options={newSubs}

                                getOptionLabel={(option) => option?.locality}
                                onChange={(event, value) => {

                                    handleChangeHcity(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            /> */}

                          <Autocomplete
                            disabled={disEdit}
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            // disablePortal
                            // defaultValue={{ fields: { scc_name: homeCity } }}
                            value={{ fields: { scc_name: userCity } }}
                            // defaultValue={{ locality: homeCity }}
                            // value={{ locality: homeCity }}
                            // value={homeCity}

                            // id="combo-box-demo"
                            options={allHCities}
                            // options={newSubs2}
                            // options={newSubs2}

                            getOptionLabel={(option) =>
                              option?.fields?.scc_name
                            }
                            // getOptionLabel={(option) => option}
                            // onInputChange={(event, value) => {
                            //     console.log(value)

                            //     if (value) {

                            //         let char = lowerCase(value)

                            //         let arr = [...newSubs]

                            //         // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                            //         const det = arr.map((item, index) => {

                            //             if (lowerCase(item.locality).startsWith(char)) {

                            //                 return { ...item }
                            //             }
                            //         })

                            //         console.log(">>>>>", _.compact(det))

                            //         arr = _.compact(det)

                            //         arr = _.map(arr, "locality")

                            //         console.log("arr>>", arr)

                            //         arr = _.uniq(arr);

                            //         setnewSubs2(arr);

                            //     } else {
                            //         setnewSubs2([])
                            //     }
                            // }}
                            onChange={(event, value) => {
                              handleChangeHcityUser(event, value);
                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}

                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className=""
                                  placeholder={t("fCity")}
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px",
                                fontFamily:
                                  t("langKey") === "Si" ? "NotoSans" : "Inter"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          {erruserCity && (
                            <div className="text-red-600 text-sm text-right mt-2">
                              This field is required
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div>
                      <Label className="text-sm"> {t("fiContact")}</Label>
                      <Input
                        className="mt-1.5"
                        disabled={disEdit}
                        placeholder={"07xxxxxxxx"}
                        // readOnly
                        onChange={(e) => {
                          setuserContact(e.target.value);
                          seterruserContact(false);
                        }}
                        value={userContact}
                      />
                      {erruserContact && (
                        <div className="text-red-600 text-sm text-right mt-2">
                          {erruserContact}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full items-center justify-center mt-4 mb-4 lg:mb-0">
                    <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                    {disEdit === false && (
                      <button
                        className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full h-14 sm:h-11 text-slate-50 font-medium mt-8 mb-3`}
                        onClick={(e) => {
                          saveOwner(e, !selectedJob ? "ad" : "next");
                        }}
                      >
                        {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                        <span className="block  text-sm font-medium ">
                          {t("Save")}
                        </span>
                      </button>
                    )}
                  </div>
                </div>

                {/* <div className="border border-[#2d867b] border-opacity-50 w-full my-10"></div> */}
              </>
            </div>
          )}

          {!owner && (
            <div className="bg-[#F4F4F4] pb-1 sm:px-4 sm:pb-4 mt-0">
              {!modal && (
                <>
                  {selectedJob ? (
                    <div className="flex justify-end px-3 sm:px-1 pt-4">
                      {disEdit === true ? (
                        <Tooltip
                          title={"Edit"}
                          placement="top"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <div className="ml-2 bg-[#003D35] p-1 rounded-full flex items-center justify-center text-white">
                            <i
                              className={`las la-pencil-alt text-xl text-left  cursor-pointer`}
                              onClick={() => {
                                // if (item.packageExpire === 0) {
                                // console.log("item>>>>", item)
                                // setselectedJob(item);
                                // setShowModalQuickView(true);
                                // }
                                setdisEdit(false);
                              }}
                            ></i>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={"Cancel"}
                          placement="top"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <div className="ml-2 bg-[#003D35] p-1 rounded-full flex items-center justify-center text-white">
                            <i
                              className={`las la-times text-xl text-left cursor-pointer`}
                              onClick={() => {
                                // if (item.packageExpire === 0) {
                                // console.log("item>>>>", item)
                                // setselectedJob(item);
                                // setShowModalQuickView(true);
                                // }
                                setdisEdit(true);
                              }}
                            ></i>
                          </div>
                        </Tooltip>
                      )}

                      <Tooltip
                        title={"Delete"}
                        placement="top"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <div className="ml-2 bg-red-500 p-1 rounded-full flex items-center justify-center text-white">
                          <i
                            className="las la-trash-alt text-xl text-left cursor-pointer"
                            onClick={() => {
                              DeleteProfile(selectedJob?.id)
                                .then((res) => {
                                  addToast(
                                    "Profile successfully deleted",
                                    "success"
                                  );
                                  // getAllJobs();
                                  onCloseModalQuickView();
                                })
                                .catch((error) => {
                                  // setlopen(false);
                                  console.log("Error", error.response);
                                  addToast(
                                    error.response.data.message,
                                    "warning"
                                  );
                                  // setOpenToast(true);
                                  // setColor(COLORS.Orange);
                                  // setAlertClass("error");
                                  // setMsg(error.response.data.message);
                                });
                            }}
                          ></i>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="flex justify-end px-3 sm:px-1 pt-4">
                      <Tooltip
                        title={"Close"}
                        placement="top"
                        TransitionComponent={Zoom}
                        arrow
                      >
                        <div className="ml-2 bg-[#003D35] p-1 rounded-full flex items-center justify-center text-white">
                          <i
                            className={`las la-times text-xl text-left cursor-pointer`}
                            onClick={() => {
                              // if (item.packageExpire === 0) {
                              // console.log("item>>>>", item)
                              // setselectedJob(item);
                              // setShowModalQuickView(true);
                              // }
                              sethideForm(true);
                            }}
                          ></i>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}

              <div className="justify-start w-full mt-0 px-5 ">
                <span className="block text-xl font-bold flex justify-start sm:justify-start w-full text-center text-[#003d35]">
                  {/* {selectedJob ? "Edit Profile" : "Details of the Proposal Seeker"} */}
                  {selectedJob && t("langKey") === "Si" && ` ${index + 1} වන `}
                  {t("pTitleSeek")}

                  {selectedJob && t("langKey") === "En" && ` ${index + 1}`}
                </span>

                {
                  // !selectedJob &&

                  <div className="flex justify-start">
                    <span className="text-xs flex justify-start w-full text-center mt-1 mb-6 text-[#003d35]">
                      {t("pDescSeek")}
                    </span>
                  </div>
                }
              </div>
              <div className="w-full flex justify-start px-5">
                <div className="w-full">
                  <div className="w-full mt-3 space-y-4">
                    {
                      // !selectedJob &&

                      <>
                        {/* <div>
                                    <Label className="text-sm">Pricing</Label>
                                    <div class="flex w-full justify-between mt-4 ">
                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio"
                                                    disabled={parseInt(oldPkg) > 1}
                                                    aria-describedby="helper-radio-text"
                                                    checked={packageId === "1"}
                                                    onChange={(e) => {
                                                        setpackageId(e.target.value);
                                                        let day = moment().add(7, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    type="radio"
                                                    value="1"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>
                                            <div className="flex">
                                                <div class="ml-2 text-sm">
                                                    <label for="helper-radio" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 1 ? "text-gray-500" : "text-gray-900"}  dark:text-gray-300`}>Starter

                                                    </label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">Free</p>
                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 7 days."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>
                                        </div>



                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio2"
                                                    disabled={parseInt(oldPkg) > 2}
                                                    aria-describedby="helper-radio-text"
                                                    checked={packageId === "2"}
                                                    onChange={(e) => {
                                                        setpackageId(e.target.value);

                                                        let day = moment().add(28, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    type="radio"
                                                    value="2"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>

                                            <div className="flex">
                                                <div class="ml-2 text-sm">
                                                    <label for="helper-radio2" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 2 ? "text-gray-500" : "text-gray-900"}  dark:text-gray-300`}>Basic</label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">$ 15 per Ad</p>

                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 28 days.  Showcase in the homepage results"} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>

                                        </div>

                                        <div class="flex">
                                            <div class="flex items-center h-5">
                                                <input id="helper-radio3"
                                                    disabled={parseInt(oldPkg) > 3}
                                                    aria-describedby="helper-radio-text"
                                                    type="radio"
                                                    onChange={(e) => {
                                                        console.log(">>>", e.target.value)
                                                        setpackageId(e.target.value);

                                                        let day = moment().add(84, "days")
                                                        day = moment(day).format("YYYY-MM-DD")

                                                        console.log(">>>>>>>", moment(day).toDate())
                                                        setexpire(moment(day).toDate());
                                                    }}
                                                    checked={packageId === "3"}
                                                    value="3"
                                                    class="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            </div>
                                            <div className="flex">
                                                <div class="ml-2 text-sm">

                                                    <label for="helper-radio3" className={`cursor-pointer font-medium ${parseInt(oldPkg) > 3 ? "text-gray-500" : "text-gray-900"}  dark:text-gray-300`}>Plus</label>
                                                    <p id="helper-radio-text" class="cursor-pointer text-xs font-normal text-gray-500 dark:text-gray-300">$ 30 per Ad</p>
                                                </div>
                                                <div className="flex ml-2">
                                                    <Tooltip title={"One ad posting (job ad or marketplace listing) up  to 84 days.  Showcase in the homepage results.   Priority listing in search results."} placement="right-start" TransitionComponent={Zoom} arrow >
                                                        <i className="las la-info-circle cursor-pointer"></i>
                                                    </Tooltip>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {errpackages && (
                                    <div className="text-red-600 text-sm text-right mt-2">
                                        This field is required
                                    </div>
                                )} */}
                      </>
                    }

                    {/* <div ref={ref}>
                            <Label className="text-sm">Profile Name *</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter Profile Name"
                                onChange={(e) => {
                                    settitle(e.target.value);
                                    seterrtitle(false);
                                }}
                                value={title}
                            />
                            {errtitle && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                    <div className="grid gird-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-5">
                      <div>
                        <Label className="text-sm">{t("fGender")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={gender ? gender : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? GENDarr : GENDarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setgender(value);
                            seterrgender(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fGender")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setjobType(e.target.value);
                                    seterrjobType(false);
                                }}
                                value={jobType}
                            >
                                <option value={""} className="" >Select a job type</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>

                            </Select> */}
                        {errgender && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fHorr")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={horoscopStatus ? horoscopStatus : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? HORRarr : HORRarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            sethoroscopStatus(value);
                            seterrhoroscopStatus(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fHorr")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errhoroscopStatus && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fAge")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={age ? age : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? AGEarr : AGEarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setage(value);
                            seterrage(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fAge")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errage && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fCast")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={cast ? cast : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? CASTarr : CASTarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setcast(value);
                            seterrcast(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fCast")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errcast && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fRace")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={race ? race : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? RACEarr : RACEarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setrace(value);
                            seterrrace(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fRace")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errrace && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fRel")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={religion ? religion : ""}
                          // id="combo-box-demo"
                          options={
                            lan === "English" ? RELIGIONarr : RELIGIONarrSi
                          }
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setreligion(value);
                            seterrreligion(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fRel")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errreligion && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fHeight")} *</Label>
                        {/* <Input className="mt-1.5"
                                            placeholder={t('fHeight')}
                                            onChange={(e) => {
                                                setheight(e.target.value);
                                                seterrheight(false);
                                            }}
                                            value={height}
                                        /> */}
                        <div className="w-full flex space-x-2">
                          <Autocomplete
                            disabled={disEdit}
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            value={feet ? feet : ""}
                            // id="combo-box-demo"
                            options={[4, 5, 6, 7]}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setfeet(value);
                              seterrheight(false);

                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className=""
                                  placeholder={t("fFeet")}
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px",
                                fontFamily:
                                  t("langKey") === "Si" ? "NotoSans" : "Inter"
                                // border: '1px solid white'
                              }
                            }}
                          />

                          <Autocomplete
                            disabled={disEdit}
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            value={inch ? inch : ""}
                            // id="combo-box-demo"
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
                            getOptionLabel={(option) => option}
                            onChange={(e, value) => {
                              setinch(value);
                              seterrheight(false);

                              // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                              <div ref={params.InputProps.ref}>
                                <Input
                                  {...params.inputProps}
                                  className=""
                                  placeholder={t("fInch")}
                                />
                              </div>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "150px",
                                fontSize: "14px",
                                fontFamily:
                                  t("langKey") === "Si" ? "NotoSans" : "Inter"
                                // border: '1px solid white'
                              }
                            }}
                          />
                        </div>
                        {errheight && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fEdu")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={educationStatus ? educationStatus : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? EDUarr : EDUarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            seteducationStatus(value);
                            seterreducationStatus(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fEdu")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {erreducationStatus && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fJob")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={occupation ? occupation : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? OCUarr : OCUarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setoccupation(value);
                            seterrocupation(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fJob")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errocupation && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      <div>
                        <Label className="text-sm">{t("fMarr")} *</Label>

                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          // defaultValue={{ value: category }}
                          value={marriageStatus ? marriageStatus : ""}
                          // id="combo-box-demo"
                          options={lan === "English" ? MARarr : MARarrSi}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setmarriageStatus(value);
                            seterrmarriageStatus(false);

                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fMarr")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errmarriageStatus && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      {/* <div>
                            <Label className="text-sm">Job Type</Label>

                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ value: category }}
                                value={{ value: jobType, name: CATEG.find((v) => v.value === jobType) ? CATEG.find((v) => v.value === jobType).name : "" }}

                                // id="combo-box-demo"
                                options={CATEG}
                                getOptionLabel={(option) => option.name}
                                onChange={(e, value) => {
                                    setjobType(value);
                                    seterrjobType(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a job type" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />


                           
                            {errjobType && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                      {/* <div>
                            <Label className="text-sm">Country</Label>
                            <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                defaultValue={country}
                                value={country}

                                // id="combo-box-demo"
                                options={["Sri Lanka"]}
                                getOptionLabel={(option) => option}
                                onChange={(e, value) => {
                                    setcountry(value);
                                    seterrcountry(false);

                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select a country" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            />

                           
                            {errcountry && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                      {/* <div>
                            <Label className="text-sm">Country</Label>
                            <Select className="mt-1.5"
                                onChange={(e) => {
                                    setcountry(e.target.value);
                                    seterrcountry(false);
                                }}
                                value={country}
                            >
                                <option value={""}>Select a country</option>
                                <option value="Sri Lanka">Sri Lanka</option>
                                
                            </Select>
                            {errcountry && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        */}

                      <div>
                        <Label className="text-sm">{t("fCountryTwo")} *</Label>
                        <Autocomplete
                          disabled={disEdit}
                          className="mt-1.5"
                          // className="form-group"
                          // className="border-neutral-200"
                          disablePortal
                          defaultValue={country}
                          value={country}
                          // id="combo-box-demo"
                          // options={[lan === "English" ? "Sri Lanka" : "ශ්‍රී ලංකාව"]}
                          options={COUNTArr}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => {
                            setcountry(value);
                            seterrcountry(false);
                            sethomeDistrict("");
                            sethomeCity("");
                            // setCountry(value);
                          }}
                          // sx={{
                          //   // width: "80vw",
                          //   background:"transparent",
                          //   borderRadius: "20px",
                          //   // border: "none",
                          //   // borderColor: "transparent",

                          //   "& .MuiInputBase-input": {
                          //     height: 15,
                          //     // border: "none",
                          //     fontSize: "14px",
                          //     fontFamily: "'Jost', sans-serif",
                          //     // borderColor: "transparent",
                          //   },
                          //   //view
                          //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                          //     // borderColor: "transparent",
                          //   },

                          //   "&:hover .MuiOutlinedInput-input": {
                          //     color: "black",
                          //   },
                          //   "&:hover .MuiInputLabel-root": {
                          //     color: COLORS.GrayBackground,
                          //   },
                          //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          //   {
                          //     // borderColor: "transparent",
                          //   },
                          //   ".MuiAutocomplete-root fieldset:hover": {
                          //     color: "transparent",
                          //     // borderColor: "transparent",
                          //   },
                          // }}
                          renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                              <Input
                                {...params.inputProps}
                                className=""
                                placeholder={t("fCountryTwo")}
                              />
                            </div>
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: "150px",
                              fontSize: "14px",
                              fontFamily:
                                t("langKey") === "Si" ? "NotoSans" : "Inter"
                              // border: '1px solid white'
                            }
                          }}
                        />

                        {errcountry && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}
                      </div>

                      {(country === "Sri Lanka" ||
                        country === "ශ්‍රී ලංකාව") && (
                        <>
                          <div>
                            <Label className="text-sm">{t("fDis")} *</Label>

                            <Autocomplete
                              disabled={disEdit}
                              className="mt-1.5"
                              // className="form-group"
                              // className="border-neutral-200"
                              disablePortal
                              defaultValue={{ name_en: homeDistrict }}
                              value={{ name_en: homeDistrict }}
                              // id="combo-box-demo"
                              options={allHDistrictspro}
                              getOptionLabel={(option) => option.name_en}
                              onChange={(event, value) => {
                                handleChangeHdist(event, value);
                                // setCountry(value);
                              }}
                              // sx={{
                              //   // width: "80vw",
                              //   background:"transparent",
                              //   borderRadius: "20px",
                              //   // border: "none",
                              //   // borderColor: "transparent",

                              //   "& .MuiInputBase-input": {
                              //     height: 15,
                              //     // border: "none",
                              //     fontSize: "14px",
                              //     fontFamily: "'Jost', sans-serif",
                              //     // borderColor: "transparent",
                              //   },
                              //   //view
                              //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              //     // borderColor: "transparent",
                              //   },

                              //   "&:hover .MuiOutlinedInput-input": {
                              //     color: "black",
                              //   },
                              //   "&:hover .MuiInputLabel-root": {
                              //     color: COLORS.GrayBackground,
                              //   },
                              //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              //   {
                              //     // borderColor: "transparent",
                              //   },
                              //   ".MuiAutocomplete-root fieldset:hover": {
                              //     color: "transparent",
                              //     // borderColor: "transparent",
                              //   },
                              // }}
                              renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                  <Input
                                    {...params.inputProps}
                                    className=""
                                    placeholder={t("fDis")}
                                  />
                                </div>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "150px",
                                  fontSize: "14px",
                                  fontFamily:
                                    t("langKey") === "Si" ? "NotoSans" : "Inter"
                                  // border: '1px solid white'
                                }
                              }}
                            />

                            {errhomeDistrict && (
                              <div className="text-red-600 text-sm text-right mt-2">
                                This field is required
                              </div>
                            )}
                          </div>

                          <div>
                            <Label className="text-sm">{t("fCity")} *</Label>

                            {/* <Autocomplete
                                className="mt-1.5"
                                // className="form-group"
                                // className="border-neutral-200"
                                disablePortal
                                // defaultValue={{ fields: { scc_name: homeCity } }}
                                // value={{ fields: { scc_name: homeCity } }}
                                // // id="combo-box-demo"
                                // options={allHCities}
                                // getOptionLabel={(option) => option?.fields?.scc_name}
                                defaultValue={{ locality: homeCity }}
                                value={{ locality: homeCity }}

                                options={newSubs}

                                getOptionLabel={(option) => option?.locality}
                                onChange={(event, value) => {

                                    handleChangeHcity(event, value);
                                    // setCountry(value);
                                }}
                                // sx={{
                                //   // width: "80vw",
                                //   background:"transparent",
                                //   borderRadius: "20px",
                                //   // border: "none",
                                //   // borderColor: "transparent",

                                //   "& .MuiInputBase-input": {
                                //     height: 15,
                                //     // border: "none",
                                //     fontSize: "14px",
                                //     fontFamily: "'Jost', sans-serif",
                                //     // borderColor: "transparent",
                                //   },
                                //   //view
                                //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                //     // borderColor: "transparent",
                                //   },

                                //   "&:hover .MuiOutlinedInput-input": {
                                //     color: "black",
                                //   },
                                //   "&:hover .MuiInputLabel-root": {
                                //     color: COLORS.GrayBackground,
                                //   },
                                //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                //   {
                                //     // borderColor: "transparent",
                                //   },
                                //   ".MuiAutocomplete-root fieldset:hover": {
                                //     color: "transparent",
                                //     // borderColor: "transparent",
                                //   },
                                // }}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input {...params.inputProps} className="" placeholder="Select State" />
                                    </div>
                                )}
                                ListboxProps={{
                                    style: {
                                        maxHeight: "150px",
                                        fontSize: "14px",
                                        // border: '1px solid white'
                                    },
                                }}
                            /> */}

                            <Autocomplete
                              disabled={disEdit}
                              className="mt-1.5"
                              // className="form-group"
                              // className="border-neutral-200"
                              // disablePortal
                              // defaultValue={{ fields: { scc_name: homeCity } }}
                              value={{ fields: { scc_name: homeCity } }}
                              // defaultValue={{ locality: homeCity }}
                              // value={{ locality: homeCity }}
                              // value={homeCity}

                              // id="combo-box-demo"
                              options={allHCitiespro}
                              // options={newSubs2}
                              // options={newSubs2}

                              getOptionLabel={(option) =>
                                option?.fields?.scc_name
                              }
                              // getOptionLabel={(option) => option}
                              // onInputChange={(event, value) => {
                              //     console.log(value)

                              //     if (value) {

                              //         let char = lowerCase(value)

                              //         let arr = [...newSubs]

                              //         // arr = arr.filter((data) => lowerCase(data.locality).includes(char));

                              //         const det = arr.map((item, index) => {

                              //             if (lowerCase(item.locality).startsWith(char)) {

                              //                 return { ...item }
                              //             }
                              //         })

                              //         console.log(">>>>>", _.compact(det))

                              //         arr = _.compact(det)

                              //         arr = _.map(arr, "locality")

                              //         console.log("arr>>", arr)

                              //         arr = _.uniq(arr);

                              //         setnewSubs2(arr);

                              //     } else {
                              //         setnewSubs2([])
                              //     }
                              // }}
                              onChange={(event, value) => {
                                handleChangeHcity(event, value);
                                // setCountry(value);
                              }}
                              // sx={{
                              //   // width: "80vw",
                              //   background:"transparent",
                              //   borderRadius: "20px",
                              //   // border: "none",
                              //   // borderColor: "transparent",

                              //   "& .MuiInputBase-input": {
                              //     height: 15,
                              //     // border: "none",
                              //     fontSize: "14px",
                              //     fontFamily: "'Jost', sans-serif",
                              //     // borderColor: "transparent",
                              //   },
                              //   //view
                              //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                              //     // borderColor: "transparent",
                              //   },

                              //   "&:hover .MuiOutlinedInput-input": {
                              //     color: "black",
                              //   },
                              //   "&:hover .MuiInputLabel-root": {
                              //     color: COLORS.GrayBackground,
                              //   },
                              //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              //   {
                              //     // borderColor: "transparent",
                              //   },
                              //   ".MuiAutocomplete-root fieldset:hover": {
                              //     color: "transparent",
                              //     // borderColor: "transparent",
                              //   },
                              // }}

                              renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                  <Input
                                    {...params.inputProps}
                                    className=""
                                    placeholder={t("fCity")}
                                  />
                                </div>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "150px",
                                  fontSize: "14px",
                                  fontFamily:
                                    t("langKey") === "Si" ? "NotoSans" : "Inter"
                                  // border: '1px solid white'
                                }
                              }}
                            />

                            {errhomeCity && (
                              <div className="text-red-600 text-sm text-right mt-2">
                                This field is required
                              </div>
                            )}
                          </div>
                        </>
                      )}

                      {/* <div>
                                                <Label className="text-sm">{t('fiContact')} </Label>
                                                <div className="mt-1.5 flex">
                                                    <span className={` ${disEdit ? "bg-gray-300" : ""} inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}>
                                                        <i className="text-xl las la-phone-volume"></i>
                                                    </span>
                                                    <Input
                                                        disabled={disEdit}

                                                        className="!rounded-l-none"
                                                        // defaultValue="003 888 232"
                                                        placeholder={"07xxxxxxxx"}
                                                        onChange={(e) => {
                                                            setmobile(e.target.value);
                                                            seterrmobile("");
                                                        }}
                                                        value={mobile}
                                                    />
                                                </div>
                                                {errmobile && (
                                                    <div className="text-red-600 text-sm text-right mt-2">
                                                        {errmobile}
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                <Label className="text-sm">{t('fWtsap')}</Label>
                                                <div className="mt-1.5 flex">
                                                    <span className={` ${disEdit ? "bg-gray-300" : ""} inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}>
                                                        <i className="text-xl las la-phone-volume"></i>
                                                    </span>
                                                    <Input
                                                        disabled={disEdit}

                                                        className="!rounded-l-none"
                                                        // defaultValue="003 888 232"
                                                        placeholder={"07xxxxxxxx"}
                                                        onChange={(e) => {
                                                            setwhatsAppNo(e.target.value);
                                                            seterrwhatsAppNo("");
                                                        }}
                                                        value={whatsAppNo}
                                                    />
                                                </div>
                                                {errwhatsAppNo && (
                                                    <div className="text-red-600 text-sm text-right mt-2">
                                                        {errwhatsAppNo}
                                                    </div>
                                                )}
                                            </div> */}

                      <div>
                        <Label className="text-sm">{t("signUpEmail")}</Label>
                        <div className="mt-1.5 flex">
                          <span
                            className={` ${
                              disEdit ? "bg-gray-300" : ""
                            } inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}
                          >
                            <i className="text-xl las la-envelope"></i>
                          </span>
                          <Input
                            disabled={disEdit}
                            className="!rounded-l-none"
                            placeholder="example@email.com"
                            onChange={(e) => {
                              setemail(e.target.value);
                              seterremail(false);
                            }}
                            value={email}
                          />
                        </div>
                        {erremail && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            {erremail}
                          </div>
                        )}
                      </div>

                      {/* <div>
                                        <Label className="text-sm">Secondary Email</Label>
                                        <div className="mt-1.5 flex">
                                            <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                                <i className="text-xl las la-envelope"></i>
                                            </span>
                                            <Input
                                                className="!rounded-l-none"
                                                placeholder="example@email.com"
                                                onChange={(e) => {
                                                    setemail2(e.target.value);
                                                    seterremail2(false);
                                                }}
                                                value={email2}
                                            />

                                        </div>
                                        {erremail2 && (
                                            <div className="text-red-600 text-sm text-right mt-2">
                                                This field is required
                                            </div>
                                        )}
                                    </div> */}

                      <div className="sm:col-span-2">
                        <div className="flex flex-col sm:flex-row sm:items-end space-y-2 sm:space-x-2">
                          <Label className="text-sm">{t("fFam")} *</Label>
                          <div className="text-xs">
                            (
                            {t("langKey") === "Si"
                              ? "මව, පියා, සහෝදර සහෝදරියන්ගේ රැකියා පිළිබඳ ආදී විස්තර ඇතුලත් කරන්න"
                              : ""}
                            )
                          </div>
                        </div>

                        {/* <Input className="mt-1.5"
                                            placeholder="Enter Family Background"
                                            onChange={(e) => {
                                                setfamilyBackground(e.target.value);
                                                seterrfamilyBackground(false);
                                            }}
                                            value={familyBackground}
                                        /> */}
                        <Textarea
                          className="mt-1.5"
                          rows={5}
                          disabled={disEdit}
                          placeholder={
                            t("langKey") === "Si"
                              ? "සිංහල ටයිප් කල හැකි වෙනත් මෘදුකාංගයක් ආදාරයෙන් ( Helakuru සිංහල Typing වැනි) අදාල විස්තර ටයිප් කර මෙහි ඇතුලත් කරන්න."
                              : t("fFam")
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            setfamilyBackground(value);

                            // Remove field required error
                            seterrfamilyBackground(false);

                            // Check if value length is less than 100 characters
                            // if (value.length < 100) {
                            //   setErrFamilyBackgroundLength(true);
                            // } else {
                            //   setErrFamilyBackgroundLength(false);
                            // }
                          }}
                          value={familyBackground}
                        />

                        {errfamilyBackground && (
                          <div className="text-red-600 text-sm text-right mt-2">
                            This field is required
                          </div>
                        )}

                        {/* {errFamilyBackgroundLength && (
                          <div className="text-red-600 text-sm text-right mt-2">
                         { t("langKey") === "Si" ? "පවුල් පසුබිම ගැන තවත් තොරතුරු ඇතුලත් කරන්න" : "Please add more details about the family background"} 
                          </div>
                        )} */}
                        {t("langKey") === "Si" && !disEdit && (
                          <>
                            <a
                              href="https://helakuru.lk/keyboard"
                              target="_blank"
                            >
                              <div className="text-xs text-right mt-2 cursor-pointer text-[#2d867b] font-bold">
                                Helakuru සිංහල Typing වෙත පිවිසෙන්න
                              </div>
                            </a>
                          </>
                        )}
                      </div>
                    </div>

                    {/* <div>
                            <Label className="text-sm">Duration</Label>
                            <Input className="mt-1.5"
                                placeholder="Enter duration"
                                onChange={(e) => {
                                    setduration(e.target.value);
                                    seterrduration(false);
                                }}
                                value={duration}
                            />
                            {errduration && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                    {/* <div>
                            <Label className="text-sm">Job Description</Label>
                            <Textarea className="mt-1.5" rows={8}
                                placeholder="Add brief description"
                                onChange={(e) => {
                                    setdescription(e.target.value);
                                    seterrdescription(false);
                                }}
                                value={description}
                            />
                            {errdescription && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                    {/* <div>
                            <Label className="text-sm">Duties and Responsibilities</Label>
                            <CKEditor

                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                    console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}
                            />
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}
                    {/* <div>
                            <Label className="text-sm">Hourly Rate</Label>
                            <div className="w-full flex">
                                <Select className="mt-1.5 w-4/12" rounded="rounded-l-lg"

                                    onChange={(e) => {
                                        setcurrency(e.target.value);
                                        seterrrate(false);
                                    }}
                                    value={currency}
                                >
                                    <option value={""}>Currency</option>
                                    <option value="AUD">AUD</option>

                                </Select>
                                <Input className="mt-1.5" rounded="rounded-r-lg"
                                    placeholder="Enter hourly rate"
                                    type="number"
                                    onChange={(e) => {
                                        setrate(e.target.value);
                                        seterrrate(false);
                                    }}
                                    value={rate}
                                />

                            </div>

                            {errrate && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                    {/* <div>
                            <Label className="text-sm">Expiry date</Label>
                          

                            <div className="flex w-full">

                                <DatePicker
                                    readOnly
                                    format="DD/MM/YYYY"
                                    containerStyle={{
                                        width: "100%"
                                    }}
                                    render={<CustomInput />}

                                    onChange={(e, val) => {
                                        console.log(">>>>>>", e.toDate())
                                        setexpire(e.toDate());
                                        seterrexpire(false);
                                    }}
                                    value={expire}
                                // value={year}
                                // onChange={setyear}

                                />

                            </div>

                           
                            {errexpire && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                    {/* <div>
                            <Label className="text-sm">Type Keywords</Label>
                            <Input className="mt-1.5"
                                placeholder="#plumber #farmingjobs"

                                onChange={(e) => {
                                    setKeywords(e.target.value);
                                    seterrKeywords(false);
                                }}
                                value={Keywords}
                            />
                            {errKeywords && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div> */}

                    {/* <div className="w-full mt-3 xl:mt-6 flex-1 border-2 border-dashed dark:border-darkmode-400 rounded-md pt-4">
                                    <div className="grid grid-cols-10 gap-5 pl-4 pr-5">
                                        {images?.map((faker, fakerKey) => (
                                            <>
                                                <div
                                                    // key={fakerKey}
                                                    className="col-span-5 md:col-span-2 h-28 relative image-fit cursor-pointer zoom-in"
                                                >
                                                    <img
                                                className="rounded-md"
                                                alt="Midone - HTML Admin Template"
                                                src={faker.path.includes('/uploads/products/') === true ? IMAGE_SERVER_URL + faker?.path : faker?.path}
                                            />
                                                    <div className="flex flex-col">
                                                        <img className="cursor-pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nO3Xv0skMRQH8GnGH6yoWCiChbJgZaGNtYWNnf+CFqJYWIpiJ8gVgjaiYiX+BRaCrY0iy3EWarGIhYiLhQiLyWzmzeR7JKO76KLHqclucB6EYbr3Sd6bl/G8NNIwG8z3YWj9chfQ12cPwQwAwj+/wbJZOwgTALov2EMwQwBrCGYQQDYQpgFkGmEDQCYRtgBkCmETQCYQtgH0BlGXgP9ZXgrw0xOAcyVUGhuDzOchi0WECwvuAeTtLcoRx+C9ve4AeFdXJfejI/0sjY+7A2CZDBBFCeDiwkGA7yM+OyuXj4pgeNgtQLi4WOkBIrC2NrcAvLsbCILkEHK5TyfPajmJo4ODBHB8DNbQ4BigqQmyUChXkZiddW+Q6RBCP9RA4/397gBod1cnHq6sINrfTxBXV+A9PfUP4KqBn570LODZLHhnJ+LLS42IT0/16Yi5OdDGBmhnB7S9DTExAdbcXB+AcHlZJ6uaWL0Hg4OgrS38K2hvr/YA3tEB+fCQ7PbJCeT19essn3viJWQ+j/j8PHnhvMaATAa0tla1s6p8wtVVlEZHwVpa9JBTTf02osPDGgEaGyFmZiBvbio7Wyzq+n7vCqEufGJyErS+DtrchJieBmtvtw8IBgZ0Y77a8VxOl9Jnvl7MJiAYGYF8fKyq8WBo6NuSZ8YAatI+l4y8uyvfOsOlpW9NnhkDtLZWfVHU8PrKnYfZLiExNaV3X/0+hvPz7w6iugUwS8tLAX56AkhL6EeXUBppeB/GX+axT5lwmtMeAAAAAElFTkSuQmCC" />
                                                        <span className="text-xs flex justify-center text-center">{faker?.name}</span>
                                                    </div>

                                                    <div
                                                        className="tooltip w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-red-600 right-0 top-0 -mr-2 -mt-2"
                                                    >
                                                        <div
                                                            onClick={(e) => {

                                                                var array = [...images]
                                                                array.splice(fakerKey, 1);
                                                                setImages(array)

                                                            }}
                                                        >
                                                           
                                                            <i className="las la-times text-base text-center  flex items-center justify-center"></i>
                                                        </div>
                                                    </div>


                                                </div>
                                               
                                            </>

                                        ))}
                                    </div>
                                    <div className="px-4 pb-4 flex items-center justify-center cursor-pointer relative text-sm mt-4">
                                      
                                        <i className="las la-file-alt text-xl text-center  mr-2 flex items-center justify-center"></i>
                                        <span className="text-primary mr-1">
                                            Upload documents
                                        </span>{" "}
                                        or drag and drop
                                        <input
                                            id="horizontal-form-1"
                                            type="file"
                                            multiple
                                            accept={"application/pdf"}
                                            className="w-full h-full top-0 left-0 absolute opacity-0"
                                            onChange={(e) => {

                                                Array.from(e.target.files).map((item) => {

                                                    let file = item;
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    let fileInp = item;
                                                    let baseURL;
                                                    // on reader load somthing...
                                                    reader.onload = () => {
                                                        // Make a fileInfo Object
                                                        baseURL = reader.result;
                                                        let obj =
                                                        {
                                                            path: baseURL,
                                                            thumbnail: false,
                                                            name: file.name,
                                                        }
                                                            ;

                                                        console.log(">>>>>", obj);

                                                        setImages(existing => [...existing, obj]);

                                                    };
                                                })



                                            }}
                                        />
                                    </div>
                                </div> */}

                    {(selectedJob?.packageExpire === 0 || !selectedJob) && (
                      <div className="w-full items-center justify-center mt-4">
                        <BotToast open={bOpen} msg={bMsg} status={bStatus} />

                        {!selectedJob && (
                          <button
                            className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-white
        w-full sm:h-11 text-slate-50 font-medium mt-8 mb-4 border border-[#2d867b] text-[#2d867b] py-2 px-2 sm:px-4`}
                            onClick={(e) => {
                              saveJob(e);
                              // window.scrollTo({
                              //     top: 0,
                              //     behavior: "smooth",
                              // });
                            }}
                          >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span
                              className={`block ${
                                t("langKey") === "Si" ? "text-xs" : " text-sm "
                              } font-medium `}
                            >
                              {t("pButton1")}
                            </span>
                          </button>
                        )}

                        {((selectedJob && disEdit === false) ||
                          !selectedJob) && (
                          <button
                            className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#2d867b]
        w-full sm:h-11 text-slate-50 font-medium mt-4 mb-4 lg:mb-4 py-2 px-2 sm:px-4`}
                            onClick={(e) => {
                              saveJob(e, !selectedJob ? "ad" : "next");
                            }}
                          >
                            {/* <i className="las la-pencil-alt text-2xl text-left mr-2"></i> */}
                            <span
                              className={`block ${
                                t("langKey") === "Si" ? "text-xs" : " text-sm "
                              } font-medium `}
                            >
                              {!selectedJob ? t("pButton2") : t("Save")}
                            </span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Jobs;
